let closeButton: HTMLElement = document.querySelector(".close")!;
let background: HTMLElement = document.querySelector(".background")!;
let popup = document.querySelector(".popup");

closeButton.onclick = background.onclick = () => {
	popup?.classList.add("hidden");
};

//@ts-ignore
window.showPopup = () => {
	popup?.classList.remove("hidden");
};
